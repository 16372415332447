
import { defineComponent } from 'vue'
import PRODUCT, {initProductCard} from "@/interfaces/product";
import apiService from "@/api/api";

export default defineComponent( {
  name: "ProductPage",
  data() {
    const product: PRODUCT = initProductCard()
    const reviews: any[] = []
    return {
      mainImageAspectRatio: 1.5,
      mainImageIndex: -1,
      imageHeight: 0,
      reviews,
      product,
      sliderMovesForward: false
    }
  },
  // computed: {
  //   currentImage(): string {
  //     if (this.mainImageIndex < 0) return this.getSrc(this.product.mainImage)
  //     else return this.getSrc(this.product.otherImages[this.mainImageIndex])
  //   }
  // },
  beforeMount() {
    const productID = +this.$route.params.id
    let rvp = localStorage.activity_watcher
    console.log('PRODUCT ID: ', productID)
    console.log('RVP: ', typeof rvp, ' |', rvp, '|')
    let arr: Array<number>
    if (!rvp || rvp === '') {
      arr = new Array<number>()
      arr.push(productID)
      console.log('ARRAY: ', arr)
      // localStorage.setItem('activity_watcher', JSON.stringify(arr))
      localStorage.activity_watcher = JSON.stringify(arr)
    } else {
      arr = JSON.parse(rvp)
      if (arr.indexOf(productID) < 0) {
        arr.push(productID)
        // console.log('ARRAY: ', arr)
        localStorage.activity_watcher = JSON.stringify(arr)
      }
    }
    // this.product = this.$store.getters.testProduct(productID)
    // if (!Array.isArray(this.product.images)) {
    //   this.product.images = this.product.images.split(',')
    // }
    // if (this.product.images.length) this.mainImageIndex = 0
    apiService.get('p', productID).then((r: any): any => {
      console.log(r)
      if (r.data.error) {
        return
      }
      if (r.data.products && r.data.products.length) {
        this.product = initProductCard(r.data.products[0])
        if (this.product.otherImages.length > 1) this.mainImageIndex = 0
        console.log('PRODUCT: ', JSON.stringify(this.product))
      }
    })
  },
  mounted() {
    this.setImgHeight()
    window.addEventListener('resize', this.setImgHeight)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setImgHeight)
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getSrc(image, 'p')
    },
    currentImage(): string {
      if (this.mainImageIndex < 0) return this.product.mainImage
      else return this.product.otherImages[this.mainImageIndex]
    },
    setImgHeight() {
      // console.log('refs: ', this.$refs)
      const imgDiv: HTMLDivElement = this.$refs['main-image'] as HTMLDivElement
      console.log(imgDiv.clientWidth, this.mainImageAspectRatio)
      if (imgDiv != null) this.imageHeight = imgDiv.clientWidth / this.mainImageAspectRatio
      // if (imgDiv != null) imgDiv.style.height = (imgDiv.clientWidth / this.mainImageAspectRatio) + 'px'
    },
    switchForward() {
      let delay = 0
      if (!this.sliderMovesForward) {
        delay = 100
        this.sliderMovesForward = true
      }
      setTimeout(() => {
        if (this.mainImageIndex === this.product.otherImages.length - 1) this.mainImageIndex = 0
        else this.mainImageIndex++
      }, delay)
    },
    switchBackward() {
      let delay = 0
      if (this.sliderMovesForward) {
        delay = 100
        this.sliderMovesForward = false
      }
      setTimeout(() => {
        if (this.mainImageIndex === 0) this.mainImageIndex = this.product.otherImages.length - 1
        else this.mainImageIndex--
      }, delay)
    }
  }
})
